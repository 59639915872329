.about-us {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
}

.about-us div {
	flex: 1;
	padding: 0 20px;
}

.x-center {
	justify-content: center;
	text-align: center;
}

.team-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.member {
	display: flex;
	padding: 10px;
	width: 300px;
}

.member .img-wrapper {
	width: 125px;
	height: 125px;
	margin-right: 10px;
	border-radius: 50%;
	background: grey;
	overflow: hidden;
}

.member img {
	width: 100%;
}

.member h5 {
	margin-top: 10px;
}

.spacer {
	border-top: 1px solid lightgrey;
	height: 20px;
	margin-top: 10px;
}
