.methodology {
	padding: 30px 100px !important;
}

.methodology-section {
	display: flex;
	margin-bottom: 30px;
}

.methodology-section > img {
	width: 60%;
}

.attributes-section img {
	margin: 20px auto;
	width: 70%;
	text-align: center;
	display: block;
}

.attributes-sub-section {
	display: flex;
	margin-top: 30px;
}

.attributes-sub-section div {
	width: 50%;
	margin: 0 8px;
	padding: 20px;
	background: rgba(0, 0, 0, 0.1);
}

.attributes-sub-section h4 {
	margin-top: 0px;
}

.attributes-sub-section li {
	margin-bottom: 6px;
}
