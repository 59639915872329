.indicator-section-container {
	font-size: 12px;
	position: sticky;
	top: 0;
	display: grid;
	grid-template-columns: 50% 50%;
	background: white;
	border-top: 1px solid lightgrey;
	border-bottom: 1px solid lightgrey;
	max-width: 100%;
	z-index: 2;
}

.indicator-section-container > div:first-child {
	border-right: 1px solid lightgrey;
}

.indicator-section-container .heading {
	text-align: center;
	font-weight: bold;
	letter-spacing: 0.05rem;
	font-size: inherit;
	padding: 10px 0;
	border-bottom: 1px solid lightgrey;
	cursor: pointer;
}

.indicator-group-container {
	display: grid;
	max-width: 100%;
	padding: 0 10px;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	column-gap: 10px;
}

.indicator-select {
	display: flex;
	position: relative;
	align-items: center;
	flex-direction: column;
	padding: 10px 0;
	height: 100%;
	cursor: pointer;
}

.indicator-select .label {
	font-weight: bold;
	letter-spacing: 0.05rem;
	text-align: center;
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.indicator-select .label.active {
	color: steelblue;
}

.indicator-select .label p {
	margin: 0;
}

.indicator-select .caret {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.indicator-select .caret svg {
	transition: transform 0.3s ease-in-out;
}

.indicator-select .caret.open svg {
	transform: rotateZ(-180deg);
}

.indicator-select .dropdown {
	position: absolute;
	top: 100%;
	width: 80%;
	margin: -10px auto 0;
	background: white;
	padding: 5px;
	box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
	transform-origin: top;
	transform: rotateX(90deg);
	opacity: 0;
	cursor: pointer;
	transition: all 0.3s ease-in;
}

.indicator-select .dropdown.open {
	transform: rotateX(0);
	opacity: 1;
}

.indicator-select .dropdown .option {
	padding: 5px;
	text-align: center;
	opacity: 0;
	transition-delay: 0.2ms;
	transition: opacity 0.2s ease-in;
}

.indicator-select .dropdown.open .option {
	opacity: 1;
}

.indicator-select .dropdown .option:hover {
	background: lightsteelblue;
}

.indicator-select .dropdown .option.active {
	background: lightsteelblue;
}

.reset-indicators {
	position: absolute;
	top: 100%;
	left: 0%;
	cursor: pointer;
	padding: 10px;
	opacity: 0;
	transition: opacity 0.5s ease-in;
	z-index: -1;
}

.reset-indicators.visible {
	opacity: 1;
}

.reset-indicators:hover {
	color: steelblue;
}

.indicator-label {
	position: absolute;
	top: 100%;
	left: 50%;

	transform: translateX(-50%);
	display: flex;
	margin: 0 auto;
	background: white;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	padding: 8px 32px;
	border: 1px solid lightgrey;
	border-top: none;
	z-index: -1;
	box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.2);
}
