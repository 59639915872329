.full-width {
	width: 100%;
	max-width: 100%;
}

.strong {
	font-weight: bold;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button {
	border: none;
	padding: 5px 10px;
	border-radius: 4px;
	background: white;
	box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

a {
	text-decoration: none;
	color: inherit;
}

.disabled {
	opacity: 0.5;
	cursor: default;
}
