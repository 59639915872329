@import url(styles/map.css);
@import url(styles/layout.css);
@import url(styles/slider.css);
@import url(styles/utils.css);
@import url(styles/indicators.css);
@import url(styles/line.css);
@import url(styles/attributes.css);
@import url(styles/country-comparison.css);
@import url(styles/country-profiles.css);
@import url(styles/about-us.css);
@import url(styles/methodology.css);

* {
	box-sizing: border-box;
	font-family: "Roboto";
}

:root {
	--primary: #0a3d5c;
	--primary-dark: #2f6d94;
	--secondary: #2f6d94;
	/* --secondary: #3c6e71; */
	--grey: #c4c4c4;
}
