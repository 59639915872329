.main {
	display: flex;
	max-width: 100vw;
	height: 100vh;
}

.left-nav {
	display: flex;
	flex-direction: column;
	width: 220px;
	background: var(--primary-dark);
	color: white;
	flex-shrink: 0;
	justify-content: space-between;
}

.left-nav a {
	display: block;
	padding: 10px 20px 10px 40px;
}

.left-nav a.active {
	background: #ffffff10;
	font-weight: normal;
}

.left-nav h3 {
	margin: 0;
	padding: 10px 20px;
}

.left-nav .bottom {
	min-height: 300px;
	padding-top: 10px;
	border-top: 1px solid white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.main section {
	flex-grow: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background: #ebebeb;
}

.main .title {
	font-size: 28px;
	font-weight: bolder;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	text-align: center;
	padding: 30px 0;
	background-image: url("../images/headerBanner.png");
	background-position: top 0px right 0px;
	color: white;
}

.main .title .subtitle {
	font-size: 18px;
	font-weight: bolder;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	text-align: center;
	margin-top: 10px;
}

.chart-section {
	display: flex;
	z-index: 1;
	overflow-y: scroll;
}

.chart-section nav {
	width: 200px;
	padding: 20px;
	background: var(--secondary);
	height: 100%;
	color: white;
}

.chart-section .chart {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: max-content;
	animation: slide 0.4s ease-in;
	background: white;
	border-radius: 10px;
	padding: 30px;
	margin: 30px;
	box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.1);
	padding-bottom: 50px;
}

@keyframes slide {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0%);
	}
}
.timeline {
	padding: 0 80px;
}

.country-compare-select {
	position: sticky;
	top: 0;
	height: 100%;
	width: 200px;
}

.attribute-list {
	position: sticky;
	top: 0;
	height: 100%;
	width: 200px;
	background: var(--primary-dark);
	padding: 10px 0;
	color: white;
}

.attribute-list h3 {
	text-align: center;
}

.attribute-list button {
	display: block;
	color: white;
	background: none;
	padding: 8px 30px;
	border: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	transition: all 0.5s ease;
}

.attribute-list button.active,
button:hover {
	background: #ffffff10;
}

.attribute-list button.overall {
	font-weight: bold;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	text-align: center;
}

.contribute-fab {
	position: fixed;
	top: 30px;
	right: 50px;
	z-index: 1000;
}
