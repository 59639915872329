.map-container {
	margin: 20px auto;
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
}

.map-container .map-view {
	position: relative;
}

.map-view .country {
	height: 140px;
	width: 140px;
	position: absolute;
	cursor: pointer;
	transition: transform 0.1s ease-out;
}

.map-view .country:hover {
	transform: scale(1.2);
}

#SG {
	left: 50px;
	bottom: 90px;
}

#MY {
	left: 260px;
	top: 180px;
}

#PH {
	right: 200px;
	top: 10px;
}

#ID {
	right: 120px;
	bottom: 200px;
}

.country-svg {
	transform: rotateZ(-90deg);
}

.card {
	position: relative;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-header {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 20px;
	width: 100%;
	font-weight: bold;
}

.map-container .measure {
	font-size: 30px;
	font-weight: bolder;
}

.map-container .land {
	fill: none;
	stroke: none;
	stroke-width: 0px;
}
.map-container .land.highlight {
	opacity: 0.6;
	transition: fill 0.1s ease-in;
	stroke: white;
	stroke-width: 0.25px;
	fill: "black";
}

.map-container .land.active {
	opacity: 1;
}
