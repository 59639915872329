.attributes p {
	margin: 0;
}

.attributes .section {
	padding: 1rem 0;
	border-bottom: 1px solid #999999;
}

.attributes .header {
	font-weight: bold;
	font-size: larger;
	margin: 0 0 1rem;
}

.attributes .item-header {
	font-weight: bold;
}

.attributes .item {
	margin: 0.5rem 0;
}
