.country-comparison {
	padding: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	overflow-x: scroll;
}

.country-comparison .country {
	padding: 0 20px;
	width: 260px;
	overflow: none;
}

.country-comparison .country .country-title {
	text-align: center;
}

.country-comparison .bar {
	padding: 8px 0;
	width: 100%;
}

.country-comparison .bar .label {
	text-align: center;
	margin-bottom: 4px;
}

.country-comparison .bar .element {
	height: 20px;
	background: #838383;
	display: flex;
	margin-bottom: 20px;
}

.country-comparison .bar .highlight {
	height: 100%;
	position: relative;
}

.country-comparison .bar .highlight .highlight-label {
	display: flex;
	position: absolute;
	width: 100%;
	top: 100%;
	left: 0;
	justify-content: space-between;
}

.country-comparison .bar .highlight .base {
	transform: translateX(-50%);
}

.country-comparison .bar .highlight .value {
	transform: translateX(50%);
}
