.faq-table {
	border-collapse: collapse;
}

.faq-table th {
	padding: 12px 12px;
	border: 1px solid #aaaaaa;
}

.faq-table td {
	padding: 6px 12px;
	border: 1px solid #aaaaaa;
	vertical-align: top;
}

.faq-table .indicator-header {
	font-weight: bold;
	white-space: nowrap;
}

.faq-table .section-header {
	background: var(--primary);
	color: white;
	font-weight: 700;
	text-align: center;
}
