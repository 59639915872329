.country-menu {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	margin: 0;
}

.country-menu button {
	flex-grow: 1;
	padding: 8px;
	border-radius: 0;
	border: none;
	background: var(--primary-dark);
	color: white;
	font-size: 16px;
	transition: all 0.5s ease;
}

.country-menu button.active {
	border-bottom: 2px solid var(--primary);
	background: var(--primary);
	font-weight: bold;
}

.country-details {
	width: 600px;
	margin: 20px auto;
}

.country-details h3 {
	text-align: center;
}

.radial-chart {
	height: 500px;
	width: 100%;
}

.radial-chart text {
	font-size: medium !important;
}
